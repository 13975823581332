<template>
  <div class="secondary">
	<div v-for="item in recommend" :key="item.id">
		<div class="swiper_box" @click="toThridpage(item.id)">
			<div class="swiper_box1" :style="'background-image: url('+ item.top_image.url +');background-repeat:no-repeat;background-size:100% 100%;'">
			</div>
			<p class="swiper_box_title">{{ item.title }}</p>
			<p class="swiper_box_message">{{ item.projectIntroduction }}</p>
		</div>
	</div>
	
	<!-- direction -->
	<div class="direction">
		<ul class="direction_list">
			<li :class="item.col_cnt == 2?'direction_project direction_project_2':item.col_cnt == 3?'direction_project direction_project_3':item.col_cnt == 4?'direction_project direction_project_4':'direction_project'" v-for="item in dataList" :key="item.id" @click="toThridpage(item.id)">
				<div class="project_banner">
					<div :style="'background-image: url('+item.top_image.url+');'" ></div>
				</div>
				<div class="direction_text">
					<p class="project_category">{{ item.category }}</p>
					<p class="project_title">{{ item.title }}</p>
					<p class="project_synopsis">{{ item.projectIntroduction }}</p>
				</div>
			</li>
		</ul>
	</div>
	<contact></contact>
  </div>
</template>
<script>
// @ is an alias to /src
import contact from '@/components/contact.vue'
import direction from '@/components/direction.vue'
import $ from 'jquery'
export default {
  name: 'Secondary',
  components: {
    contact,
	direction,
  },
  watch:{
	$route(to,from){
		let _this = this;
	  if(from.path == '/secondary' && to.path == '/secondary'){
		  _this.recommend = [];
		  _this.dataList = []
		_this.getDataList(to.query.sec_page_id);
	  }else{
		  console.log('不是二级页跳转二级页')
	  }
	
	}
  },
  data(){
  	return {
		sec_page_id:'',
		recommend:[],
		dataList:[],
		category:'',
		categoryObj:{
			1:'品牌设计',
			2:'展览介绍',
			3:'文旅规划',
			4:'景观色彩'
		}
	}
  },
  mounted() {
	let _this = this;
	_this.sec_page_id = _this.$route.query.sec_page_id;
	_this.category = _this.categoryObj[_this.$route.query.sec_page_id];
	_this.getDataList(_this.sec_page_id);
  },
  methods:{
	getDataList(id){
		let _this = this;
		_this.$axios({
		  method: 'get',
		  url: 'page_manager/thrid_page?sec_page_id='+id,
		}).then(function(response) {
		  console.log(response);
		  if(response.data.code == 0){
			_this.dataList = _this.cloneLoop(response.data.data);
			var ssss = _this.secPageRowFormat(response.data.sec_page_row_format);
		
			let sum = eval(response.data.sec_page_row_format.join('+'));
			for(var i in _this.dataList){
				if(_this.dataList[i].is_recommend){
					_this.recommend = _this.dataList.splice(i,1);
				}
				_this.dataList[i].col_cnt = ssss[ i % sum];
				
			}
			$(function(){
				$('.direction_project').mouseover(function(){
					$(this).css({'background-color': 'rgb(240,240,240)'}).children('.project_banner').css({'height':'2.8rem'}).children('div').css({'transform':'scale(1.1,1.1)'}).parent('.project_banner').siblings('.direction_text').css({'padding-bottom':'0.6rem'});
				}).mouseout(function(){
					$(this).css({'background-color': 'rgb(255,255,255)'}).children('.project_banner').css({'height':'2.6rem'}).children('div').css({'transform':'scale(1,1)'}).parent('.project_banner').siblings('.direction_text').css({'padding-bottom':'0.8rem'});
				})
			})
		  }
		  
		});
	},
	toThridpage(id){
		let _this = this;
		_this.$router.push({path:'/thridpage',query:{id:id}});
	},
	cloneLoop(x) {
	    const root = [];
	
	    // 栈
	    const loopList = [
	        {
	            parent: root,
	            key: undefined,
	            data: x,
	        }
	    ];
	    while(loopList.length) {
	        // 深度优先
	        const node = loopList.pop();
	        const parent = node.parent;
	        const key = node.key;
	        const data = node.data;
	
	        // 初始化赋值目标，key为undefined则拷贝到父元素，否则拷贝到子元素
	        let res = parent;
	        if (typeof key !== 'undefined') {
	            res = parent[key] = {};
	        }
	
	        for(let k in data) {
	            if (data.hasOwnProperty(k)) {
	                if (typeof data[k] === 'object') {
	                    // 下一次循环
	                    loopList.push({
	                        parent: res,
	                        key: k,
	                        data: data[k],
	                    });
	                } else {
	                    res[k] = data[k];
	                }
	            }
	        }
	    }
	
	    return root;
	},
	secPageRowFormat(list){
		var result = {};
		var total = 0;
		var row_fowmat_list = list;
		
		for(var i=0;i<row_fowmat_list.length;i++)
		{
		    var curr_col_cnt = row_fowmat_list[i];
		    for(var j=0;j<curr_col_cnt;j++){
		        result[total+j] = curr_col_cnt;
		    }
		    total = total + curr_col_cnt;
		}
		return result;
	}
  }
}
</script>
<style>
	.swiper_box{
		cursor: pointer;
	}
	.direction_project_2{
		width: 50%;
		overflow: hidden;
		background-color: #fff;
		margin-bottom:0.5rem;
	}
	.direction_project_3{
		width: 50%;
		overflow: hidden;
		background-color: #fff;
		margin-bottom:0.5rem;
	}
	.direction_project_4{
		width: 50%;
		overflow: hidden;
		background-color: #fff;
		margin-bottom:0.5rem;
	}
	@media screen and (min-width:751px){
		.direction_project_2{
			width: 50%;
			cursor: pointer;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		.direction_project_3{
			width: 33.33%;
			cursor: pointer;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		.direction_project_4{
			width: 25%;
			cursor: pointer;
			-webkit-transition: all 0.2s ease-in-out;
			transition: all 0.2s ease-in-out;
		}
		
	}
</style>